import OSS from 'ali-oss'
// import { GET } from "@/utils/request";
import { ElMessage, ElLoading } from "element-plus";

new OSS({
  // yourRegion填写Bucket所在地域。https://oss-cn-shanghai.aliyuncs.com
  region: 'oss-cn-shanghai',
  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
  accessKeyId: 'LTAI5tAtmYpSKcYH9bEu7CkF',
  accessKeySecret: 'M2jt0V7KidlFOMXrlzZZq2XLVHZgVl',
  // endpoint: 'https://oss-cn-shanghai.aliyuncs.com',
  // 从STS服务获取的安全令牌（SecurityToken）。
  // stsToken: 'yourSecurityToken',
  // refreshSTSToken: async () => {
  // // 向您搭建的STS服务获取临时访问凭证。
  //   const info = await fetch('your_sts_server');
  //   return {
  //     accessKeyId: info.accessKeyId,
  //     accessKeySecret: info.accessKeySecret,
  //     stsToken: info.stsToken
  //   }
  // },
  // 刷新临时访问凭证的时间间隔，单位为毫秒。
  refreshSTSTokenInterval: 300000,
  // 填写Bucket名称。
  bucket: 'jakabucket-shanghai'
});

// 判断文件是否存在
async function isExistObject(client, name, options = {}) {
  try {
    await client.head(name, options)
    return true
  } catch (error) {
    if (error.code === 'NoSuchKey') {
      console.log('文件不存在')
    }
    return false
  }
}

async function ossUpload(name, file, {progress, path, timestamp=true}) {
  try {
    // const result = await GET('getAliOssKey')
    var loading = ElLoading.service({
      text: '上传中',
      background: 'rgba(50,50,50,.1)'
    })
    const client = new OSS({
      region: 'oss-cn-shanghai',
      accessKeyId: 'LTAI5tAtmYpSKcYH9bEu7CkF',
      accessKeySecret: 'M2jt0V7KidlFOMXrlzZZq2XLVHZgVl',
      // stsToken: 'yourSecurityToken',
      bucket: 'jakabucket-shanghai',
      secure: true
    })
    let fileName = `${path || '/ota'}/${Date.now()}-${name}`
    if (timestamp === false) {
      fileName = `${path || '/ota'}/${name}`
    }
    // Math.random().toString().slice(-8)
    const headers = {
      // 'x-oss-forbid-overwrite': true
    }
    // object表示上传到OSS的文件名称。
    // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
    const r1 = await client.multipartUpload(fileName, file, {
      headers,
      progress: function (p, checkpoint) {
        // console.log(p, checkpoint)
        checkpoint
        progress && progress(p)
      },
    })
    const url = client.signatureUrl(fileName, {
      expires: 864000000 // 86400 * 10000
    })
    loading.close()
    ElMessage({
      showClose: true,
      type: "success",
      message: "上传成功!"
    })
    console.log('get success: %j', r1, url);
    return url
  } catch (error) {
    loading.close()
    ElMessage({
      showClose: true,
      type: "error",
      message: error.message || "上传失败!"
    })
    console.error('error: %j', error);
    return Promise.reject()
  }
}


export {
  isExistObject,
  ossUpload
}