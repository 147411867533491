<template>
  <Editor id="tinymce" v-model="tinymceData" :init="tinymceInit" :disabled="false"></Editor>
  <!-- <div id="textarea1"></div> -->
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
// import { ElMessage } from "element-plus";
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons'
// import 'tinymce/skins/content/default/content.css'

import 'tinymce/plugins/image'
import 'tinymce/plugins/fullscreen' //全屏
import 'tinymce/plugins/preview'//预览
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/save'  //保存 
import 'tinymce/plugins/searchreplace'  //查找替换
import { ossUpload } from '@/utils/oss'

export default {
  // props: {
  //   value: {
  //     type: String,
  //     default: ''
  //   }
  // },
  components: {
    Editor
  },
  setup() {
    const state = reactive({
      tinymceData: '',
      tinymceInit: {
        selector: '#textarea1',
        // inline: true, //开启内联模式
        skin_url: './tinymce/skins/ui/oxide',  //皮肤：浅色
        language_url: `./tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        height: 500, // 高度
        placeholder: '在这里输入文字',
        branding: false,
        menubar: true,
        plugins: 'fullscreen preview searchreplace image save',
        toolbar: 'fullscreen undo redo | styleselect | forecolor backcolor bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent image removeformat | searchreplace preview save',
        content_style: ".rich-img_full {width:100%;height:auto;display:block;}.rich-img_center {display:block;margin: auto;}",  //直接自定义可编辑区域的css样式
        // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
        plugin_preview_width: 375,
        plugin_preview_height: 640,
        // valid_elements: "p[style|class],a[style|class],abbr[style|class],b[style|class],blockquote[style|class],br[style|class],code[style|class],col[style|class|span|width],colgroup[style|class|span|width],dd[style|class],del[style|class],div[style|class],dl[style|class],dt[style|class],em[style|class],fieldset[style|class],h1[style|class],h2[style|class],h3[style|class],h4[style|class],h5[style|class],h6[style|class],hr[style|class],i[style|class],img[style|class|alt|src|height|width],ins[style|class],label[style|class],legend[style|class],li[style|class],ol[style|class|start|type],p[style|class],q[style|class],span[style|class],strong[style|class],sub[style|class],sup[style|class],table[style|class|width],tbody[style|class],td[style|class|colspan|height|rowspan|width],tfoot[style|class],th[style|class|colspan|height|rowspan|width],thead[style|class],tr[style|class],ul[style|class]",
        // image_advtab: true,
        image_class_list: [
          {title: '平铺', value: 'rich-img_full'},
          {title: '居中', value: 'rich-img_center'},
        ],
        images_upload_handler: (blobInfo, succFun, failFun) => {
          ossUpload(blobInfo.filename(), blobInfo.blob(), {
            path: '/ota/activity',
            timestamp: true
          })
          .then((res) => {
            console.log('images_success', res)
            succFun(res)
          })
          .catch((err) => {
            console.log('images_err', err)
            failFun(err)
          })
        },
        save_onsavecallback: function () { console.log('Saved'); },
        setup: (editor) => {
          console.log(editor)
          console.log(tinymce)
          editor.on('init', () => {
            // tinymce.activeEditor.formatter.register('custom_format', { selector: 'img', styles: { width:'100%', height:'auto', display:'block'}});
            // tinymce.activeEditor.formatter.apply('custom_format');
          })
        }
      }
    })
    onMounted(() => {
      console.log('Component is mounted!')
      // window.tinymce = tinymce
      // tinymce.init(state.tinymceInit)
      // tinymce.activeEditor.formatter.register('custom_format', { selector: 'img', styles: { width:'100%', height:'auto', display:'block'}});
      // tinymce.activeEditor.formatter.apply('custom_format');
      // tinymce.DOM.addClass(tinymce.activeEditor.dom.select('img'), 'rich-img');
      // tinymce.activeEditor.dom.addClass(tinymce.activeEditor.dom.select('img'), 'rich-img');
    })
    function setImg() {
       tinymce.activeEditor.dom.addClass(tinymce.activeEditor.dom.select('img'), 'rich-img');
    }
    function getContent() {
      console.log(tinymce.activeEditor.getContent())
    }
    return {
      ...toRefs(state),
      setImg,
      getContent
      
    };
  },
};
</script>
<style>

</style>
<style scoped>

</style>