import { baseDomain } from '@/common/config'
import { service } from '@/utils/request.js'

const Api = {
  Upgrade_List: baseDomain + '/upgrade/getRobotUpgradeLog', // 升级日志列表
  Upgrade_Send: baseDomain + '/upgrade/robotUpgrade', // 远程升级下发
  Upgrade_Version: baseDomain + '/version/getRobotProductVersion/', // 获取升级版本信息
  Collect_TaskList: baseDomain + '/otaData/collect/taskList', // 获取采集任务列表
}

export const reqUpgradeList = (params) => {
  return service({
    url: Api.Upgrade_List,
    method: 'POST',
    data: params
  })
}
export const reqUpgradeSend = (params) => {
  return service({
    url: Api.Upgrade_Send,
    method: 'POST',
    data: params
  })
}
export const reqUpgradeVersion = (params) => {
  return service({
    url: Api.Upgrade_Version + params.id,
    method: 'GET',
  })
}
export const reqCollectTaskList = (params) => {
  return service({
    url: Api.Collect_TaskList,
    method: 'POST',
    data: params
  })
}