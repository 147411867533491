export const common = {
  data() {
    return {

    }
  },
  created: function () {

  },
  methods: {
    clearData() {
      for (let item in this.query) {
        if (Object.prototype.hasOwnProperty.call(this.query, item)) {
          this.query[item] = ''
        }
      }
    },
  }
}