import { reactive, defineComponent, computed } from 'vue'
const crypto = require("crypto-js");
import { encode } from 'js-base64'

const config = {
  host: 'https://jakabucket-shanghai.oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAI5tAtmYpSKcYH9bEu7CkF',
  accessKeySecret: 'M2jt0V7KidlFOMXrlzZZq2XLVHZgVl',
  bucket: 'jakabucket-shanghai',
  path: 'ota',
  timeout: 240000,
  maxSize: 1000
}

class MpUploadOssHelper {
  constructor(options={}) {
    this.accessKeyId = config.accessKeyId;
    this.accessKeySecret = config.accessKeySecret;
    // 限制参数的生效时间，单位为小时，默认值为1。
    this.timeout = options.timeout || config.timeout; 
    // 限制上传文件的大小，单位为MB，默认值为10。
    this.maxSize = options.maxSize || config.maxSize;
  }

  createUploadParams() {
    const policy = this.getPolicyBase64();
    const signature = this.signature(policy);
    return {
      OSSAccessKeyId: this.accessKeyId,
      policy: policy,
      signature: signature,
    };
  }

  getPolicyBase64() {
    let date = new Date();
    // 设置policy过期时间。
    date.setHours(date.getHours() + this.timeout);
    let srcT = date.toISOString();
    const policyText = {
      expiration: srcT,
      conditions: [
        // 限制上传文件大小。
        ["content-length-range", 0, this.maxSize * 1024 * 1024],
      ],
    };
    return encode(JSON.stringify(policyText))
  }

  signature(policy) {
    return crypto.enc.Base64.stringify(
      crypto.HmacSHA1(policy, this.accessKeySecret)
    );
  }

  urlSignature(fileKey) {
    let date = new Date();
    date.setHours(date.getHours() + this.timeout);
    let time = String(date.getTime())
    let srcT = time.substring(0, time.length-3)
    const signature = encodeURIComponent(crypto.enc.Base64.stringify(
      crypto.HmacSHA1(`GET\n\n\n${srcT}\n/${config.bucket}/${fileKey}`, this.accessKeySecret)
    ));
    const result = `${config.host}/${fileKey}?Expires=${srcT}&OSSAccessKeyId=${this.accessKeyId}&Signature=${signature}`
    return result
  }
}
const mpHelper = new MpUploadOssHelper()
const params = mpHelper.createUploadParams()

export default defineComponent({
  name: 'Upload',
  props: {
    modelValue: [String, Array],
    beforeUpload: [Function],
    onSuccess: [Function],
    onRemove: [Function],
    onPreview: [Function],
    type: [String]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const slots = {...context.slots}
    console.log('props', context, props, slots)
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    const state = reactive({
      viewerList: [],
      viewerIndex: 0,
    })
    const formData = {
      'key': '',
      'policy': params.policy,
      'OSSAccessKeyId': params.OSSAccessKeyId,
      'signature': params.signature,
      'success_action_status' : '200'
      // 'x-oss-security-token': securityToken // 使用STS签名时必传。
    }
    const beforeUpload = (file) => {
      formData.key = `${config.path}/${Date.now()}-${file.name}`
      return props.beforeUpload ? props.beforeUpload(file) : true
    }
    const uploadSuccess = (response, file, fileList) => {
      file.url = mpHelper.urlSignature(formData.key)
      console.log('uploadSuccess js', formData, file.url, fileList)
      model.value = fileList.map((file) => {
        return file.url
      }).join(',')
      props.onSuccess && props.onSuccess(response, file, fileList)
    }
    const uploadRemove = (file, fileList) => {
      model.value = fileList.map((file) => {
        return file.url
      }).join(',')
      props.onRemove && props.onRemove(file, fileList)
    }
    const onImagePreview = (item) => {
      console.log('onImagePreview', item)
      if (item && props.type == 'image') {
        state.viewerList = model.value.split(',')
        state.viewerIndex = state.viewerList.indexOf(item.url)
      }
      props.onPreview && props.onPreview(item)
    }
    const onPreviewClose = () => {
      state.viewerList = []
      state.viewerIndex = 0
    }
    return () => (
      <div>
        <el-upload
          {...context.attrs}
          name="file"
          action={config.host}
          data={formData}
          before-upload={beforeUpload}
          on-success={uploadSuccess} 
          on-remove={uploadRemove}
          on-preview={onImagePreview}
          v-slots={slots}
        >
        </el-upload>
        {
          state.viewerList?.length ? <el-image-viewer url-list={state.viewerList} initial-index={state.viewerIndex} teleported={true} onClose={onPreviewClose}></el-image-viewer> : ''
        }
      </div>

    )
  }
})