<template>
  <div class="table-wrapper">
    <div class="table-toolBar" v-if="$slots.toolBar">
      <slot name="toolBar"></slot>
    </div>
    <div class="table-container">
      <el-table 
        class="el-table" 
        height="100%" 
        stripe 
        :border="border"
        :className="className"
        :data="tableData"
        highlight-current-row
        tooltip-effect="light"
        @selection-change="handleSelectionChange"
        @current-change="handleSingleChange"
        @sort-change="$emit('sort-change', $event)"
      >
        <el-table-column v-if="select" :selectable="selectable" type="selection" width="60" fixed />
        <slot name="default"></slot>
      </el-table>
    </div>
    <div class="table-footer">
      <div class="table-handle">
        <slot name="footer"></slot>
      </div>
      <el-pagination
        v-show="total>0"
        background
        :layout="data.layout"
        :pager-count="data.pagerCount"
        :page-sizes="[10, 50, 200, 500]"
        :page-size="data.size"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/reactivity";

export default {
  props: {
    className: {
      type: String,
      default: "datagrid",
    },
    total: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 10
    },
    tableData: {
      type: Array,
      required: true,
    },
    select: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Function
    },
    single: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    console.log('props', props)
    console.log('context', context)
    const data = reactive({
      page: 1,
      size: props.size,
      pagerCount: 7,
      layout: 'total, sizes, prev, pager, next, jumper'
      // total: toRef(props, 'total'),
      // tableData: toRef(props, 'tableData')
    })
    if (document.documentElement.clientWidth < 500) {
      data.layout = 'total, prev, pager, next'
      data.pagerCount = 3
    }
    const multipleSelection = ref([])
    const {tableData, total} = toRefs(props)
    console.log(tableData, total)

    const handleSizeChange = (val) => {
      console.log(`每页 ${val} 条`);
      if (val === data.size) return;
      data.size = val;
      context.emit('update', data.page, data.size)
    };
    const handleCurrentChange = (val) => {
      console.log(`当前页: ${val}`);
      if (!val) return;
      data.page = val;
      context.emit('update', data.page, data.size)
    };

    const handleSelectionChange = (val) => {
      console.log('handleSelectionChange', val)
      multipleSelection.value = val;
      slectHandle()
    }
    const slectHandle = () => {
      context.emit('select', multipleSelection.value)
    }
    const handleSingleChange = (val) => {
      if (val) {
        context.emit('select', [val])
      } else {
        context.emit('select', [])
      }
    }

    return {
      data,
      multipleSelection,
      handleSizeChange,
      handleCurrentChange,
      handleSelectionChange,
      handleSingleChange,
      slectHandle
    }
  }
};
</script>

<style scoped>
.table-wrapper {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex: 1;
  overflow: hidden;
  /* padding: 0 15px; */
  margin: 5px 0px 0px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.table-footer {
  padding: 10px 10px 20px; 
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.table-toolBar {
  padding: 10px 15px;
  background-color: #fff;
  color: var(--el-text-color-regular);
  font-size: var(--el-font-size-base);
  text-align: left;
}
</style>
