<template>
  <div class="form-container">
    <el-form
      :inline="true"
      label-position="left"
      class="form-row"
      size="small"
    >
      <div class="form-col" v-for="(item, index) in params" :key="index">
        <el-form-item :label="`${item.label}: `">
          <component v-if="item.type" :is="item.type" v-model="query[item.key]" :param="item"></component>
          <el-input v-else v-model.trim="query[item.key]" :placeholder="item.label" clearable />
        </el-form-item>
      </div>
      <div class="form-col">
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="searchData"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-delete" @click="clearData"
            >清除</el-button
          >
        </el-form-item>
      </div>
      <div class="form-col" v-if="add">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="addNew"
            >新增</el-button
          >
        </el-form-item>
      </div>
      <slot name="default" :query="query"></slot>
    </el-form>
  </div>
</template>

<script>
import { reactive } from "vue";
import {   
  Input,
  Select,
  Date,
  Time,
} from '../form/formItem'

export default {
  components: {
    Input,
    Select,
    Date,
    Time,
  },
  props: {
    modelValue: {
      type: [Array, Object],
      required: true,
    },
    params: {
      type: Array,
      required: true,
    },
    add: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const query = reactive(props.modelValue)
    props.params.forEach((param) => {
      query[param.key] = param.value;
    });
    // watchEffect(() => {

    // });
    function searchData() {
      console.log('searchData', query)
      context.emit("search");
    }
    function clearData() {
      for (let item in query) {
        if (Object.prototype.hasOwnProperty.call(query, item)) {
          query[item] = undefined;
        }
      }
      context.emit("clear");
    }
    function addNew() {
      context.emit("add");
    }
    return {
      query,
      searchData,
      clearData,
      addNew,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  padding: 20px 15px 0;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  z-index: 99;
  position: relative;
}
.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
:deep(.form-col) {
  flex: 0 1 auto;
  margin-right: 20px;
}
.form-col:last-child {
  margin-right: 0;
}
:deep(.form-col .el-form-item) {
  margin-bottom: 15px!important;
}

:deep(.form-col .el-form-item__content) {
  max-width: 350px!important;
  overflow: hidden;
  .el-input {
    width: 100%!important;
  }
  .el-date-editor {
    width: 250px;
  }
  .el-range-editor {
    width: 350px;
  }
}

</style>
