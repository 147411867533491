function arrayToMap (array=[], key, value) {
  const obj = {}
  for (let item of array) {
    obj[item[key]] = item[value]
  }
  return obj
}
const robotStatusMap = {
  1: "运行",
  2: "暂停",
  3: "故障",
  4: "报警",
  5: "离线",
  6: "空闲",
  7: "未接入OTA"
};

const statusList = [
  {
    id: 1,
    label: 'run',
    name: '运行'
  },
  {
    id: 2,
    label: 'stop',
    name: '暂停'
  },
  {
    id: 3,
    label: 'error',
    name: '故障'
  },
  {
    id: 4,
    label: 'warn',
    name: '报警'
  },
  {
    id: 5,
    label: 'offline',
    name: '离线'
  },
  {
    id: 6,
    label: 'free',
    name: '空闲'
  }
  // {
  //   id: 'not_in_ota',
  //   name: '未接入OTA'
  // }
]
const verifyStatusList = [
  { name: '待审核', value: 0 },
  { name: '审核通过', value: 1 },
  { name: '审核拒绝', value: 2 },
  { name: '无需处理', value: 3 },
]

const verifyStatusMap = {
  0: '待审核',
  1: '审核通过',
  2: '审核拒绝',
  3: '无需处理',
}

const packetStatusList = [
  { name: '领取', value: 1 },
  { name: '红包开启', value: 2 },
  { name: '待下发', value: 3 },
  { name: '已发放', value: 4 },
  { name: '下发失败', value: 5 },
  { name: '下发成功', value: 6 },
  { name: '关闭', value: 7 },
]
const packetStatusMap = {
  1: '领取', 
  2: '红包开启', 
  3: '待下发', 
  4: '已发放',
  5: '下发失败', 
  6: '下发成功',
  7: '关闭'
}

// 用户状态
const userTypesList = [
  { name: '内部用户', value: 1 },
  { name: '普通用户', value: 0 },
  { name: '我是集成商', value: 2 },
  { name: '我是机器人使用者', value: 3 },
  { name: '我是经销商', value: 4 },
  { name: '我是终端客户', value: 5 },
  { name: '其他', value: 6 },
]
const userTypesMap = arrayToMap(userTypesList, 'value', 'name')

// 用户状态
const userStatusList = [
  { name: '正常', value: 1 },
  { name: '禁用', value: 100 },
]
const userStatusMap = arrayToMap(userStatusList, 'value', 'name')

// 注册渠道
const registerWayList = [
  { name: '小程序', value: 0 },
  { name: '官网', value: 1 },
]
const registerWayMap = arrayToMap(registerWayList, 'value', 'name')

// 升级状态
const otaUpgradeList = [
  { label: '未开始', value: 0 },
  { label: '等待机器人响应', value: 1 },
  { label: '升级中', value: 2 },
  { label: '升级完成', value: 3 },
  { label: '升级失败', value: 4 },
]
const otaUpgradeMap = arrayToMap(otaUpgradeList, 'value', 'label')

export {
  arrayToMap,
  robotStatusMap,
  statusList,
  verifyStatusList,
  verifyStatusMap,
  packetStatusList,
  packetStatusMap,
  userTypesList,
  userTypesMap,
  userStatusList,
  userStatusMap,
  registerWayList,
  registerWayMap,
  otaUpgradeList,
  otaUpgradeMap
}