import { defineComponent, computed } from 'vue'

const Input = defineComponent({
  name: 'Input',
  props: {
    modelValue: [String, Number],
    param: [Object]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const options = props.param.options || {}
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    return () => (
      <el-input v-model={[model.value, ["trim"]]} placeholder={props.param.label} clearable {...options} />
    )
  }
})

const Select = defineComponent({
  name: 'Select',
  props: {
    modelValue: [String, Number],
    param: [Object]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const options = props.param.options || {}
    const attrs = props.param.attrs || {}
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    console.log('select --> ', attrs)
    return () => (
      <el-select
        v-model={model.value}
        placeholder={props.param.label}
        clearable
        {...attrs}
      >
        {
          options.data && options.data.map(item => {
            const label = options.label
            const value = options.value
            return (
              <el-option
                key={item[label] ? item[value]: item}
                label={item[label] ? item[label]: item}
                value={item[label] ? item[value]: item}
              />
            )
          })
        }
      </el-select>
    )
  }
})

const Date = defineComponent({
  name: 'Date',
  props: {
    modelValue: [String, Number, Array],
    param: [Object]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const options = props.param.options || {}
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    return () => (
      <el-date-picker v-model={model.value} placeholder={props.param.label} clearable {...options}/>
    )
  }
})

const Time = defineComponent({
  name: 'Time',
  props: {
    modelValue: [String, Number, Object],
    param: [Object]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const options = props.param.options || {}
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    return () => (
      <el-time-picker v-model={model.value} placeholder={props.param.label} clearable {...options}/>
    )
  }
})

const FormItem = defineComponent({
  name: 'FormItem',
  props: {
    modelValue: [String, Number, Object],
    param: [Object]
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    // const { param } = toRefs(props)
    console.log('FormItem', props.param)
    const model = computed({
      get: () => props.modelValue,
      set: val => {
        context.emit('update:modelValue', val)
      }
    })
    const formItemTypes = {
      Input,
      Select,
      Date,
      Time
    }
    const Item = formItemTypes[props.param.type || 'Input']
    return () => (
      <Item v-model={model.value} param={props.param}/>
    )
  }
})

export {
  Input,
  Select,
  Date,
  Time,
  FormItem,
}