<template>
  <div class="table-wrapper">
    <Filter v-model="state.searchData" :add="add" :params="params" @search="search" @clear="clear" @add="$emit('add')" >
      <slot name="search" :query="{...state.searchData, page: state.page, size: state.size}"></slot>
    </Filter>
    <div class="table-toolBar" v-if="$slots.toolBar">
      <slot name="toolBar"></slot>
    </div>
    <div class="table-container">
      <el-table 
        class="el-table" 
        height="100%" 
        stripe 
        :border="border"
        :className="className"
        :data="state.tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        @current-change="handleSingleChange"
        @sort-change="handleSortChange"
      >
        <el-table-column v-if="select" type="selection" width="60"/>
        <slot name="default"></slot>
      </el-table>
    </div>
    <div class="table-footer">
      <div class="table-handle">
        <slot name="footer"></slot>
      </div>
      <el-pagination
        v-show="state.total>0"
        background
        :layout="state.layout"
        :pager-count="state.pagerCount"
        :page-sizes="[10, 30, 50, 200, 500]"
        :page-size="state.size"
        :total="state.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import Filter from './filter.vue'

export default {
  components: {
    Filter
  },
  props: {
    add: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: "datagrid",
    },
    params: {
      type: Array,
      default: () => []
    },
    request: {
      type: Function,
      required: true
    },
    size: {
      type: Number,
      default: 10
    },
    select: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
  },
  setup(props, context) {
    console.log('props', props)
    console.log('context', context)
    const state = reactive({
      searchData: {},
      sortData: {},
      tableData: [],
      page: 1,
      size: props.size,
      total: 0,
      pagerCount: 7,
      layout: 'total, sizes, prev, pager, next, jumper'
    })
    if (document.documentElement.clientWidth < 500) {
      state.pagerCount = 3
      state.layout = 'total, prev, pager, next'
    }
    const multipleSelection = ref([])

    const update = async (params={}) => {
      try {
        const res = await props.request({
          ...state.searchData,
          ...state.sortData,
          ...params,
          page: state.page,
          size: state.size,
        })
        state.tableData = res.records
        state.total = res.total;
      } catch (error) {
        console.error(error)
      }
    }

    const refresh = () => {
      state.page = 1
      update()
    }
    
    const handleSizeChange = (val) => {
      console.log(`每页 ${val} 条`);
      if (val === state.size) return;
      state.size = val;
      if ((state.page-1) * state.size > state.total) {
        return
      }
      update()
      // context.emit('update', state.searchData)
    };
    const handleCurrentChange = (val) => {
      console.log(`当前页: ${val}`);
      if (!val) return;
      state.page = val;
      update()
      // context.emit('update', state.searchData)
    };

    const handleSelectionChange = (val) => {
      console.log('handleSelectionChange', val)
      multipleSelection.value = val;
      context.emit('select', multipleSelection.value)
    }
    const handleSingleChange = (val) => {
      if (val) {
        context.emit('select', [val])
      } else {
        context.emit('select', [])
      }
    }
    const handleSortChange = ({column, prop, order}) => {
      // ascending descending null
      console.log('onSortChange', column, prop, order)
      // context.emit('sort-change', {column, prop, order})
      const orderMap = { ascending: 1, descending: 2 }
      state.sortData = {
        orderField: prop,
        orderType: orderMap[order]
      }
      update()
    }

    const search = (params={}) => {
      Object.keys(params).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(state.searchData, key)) {
          state.searchData[key] = params[key]
        }
      })
      console.log('search', state.searchData, params)
      update({
        ...params
      })
    }
    const clear = (params={}) => {
      search(params)
      context.emit('clear')
    }
    const clearData = () => {
      for (let item in state.searchData) {
        if (Object.prototype.hasOwnProperty.call(state.searchData, item)) {
          state.searchData[item] = undefined;
        }
      }
    }
    onMounted(() => {
      props.auto && update()
    })

    return {
      state,
      multipleSelection,
      handleSizeChange,
      handleCurrentChange,
      handleSelectionChange,
      handleSingleChange,
      handleSortChange,
      update,
      refresh,
      search,
      clear,
      clearData
    }
  }
};
</script>

<style scoped>
.table-wrapper {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex: 1;
  overflow: hidden;
  /* padding: 0 15px; */
  margin: 5px 0px 0px;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}
.table-toolBar {
  padding: 10px 15px;
  background-color: #fff;
  color: var(--el-text-color-regular);
  font-size: var(--el-font-size-base);
  text-align: left;
}
.table-footer {
  padding: 10px 10px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.table-handle {

}
</style>
